.App {
  font-family: Avenir, sans-serif;
}

.name {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.experience {
  background-image: url('glacier.jpg');
  background-position: center;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
  height: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  opacity: 8;
  z-index: 2;
}
/* <div className="experience">
  <div className="caption">
    <span className="border"></span>
  </div>
</div>
</div> */

@media (min-width: 700px) {
  .quote {
    text-align: center;
    font-size: 28px;
    padding-left: 100px;
    margin-top: 80px;
  }
  .profile {
    display: inline-block;
    width: 200px;
    margin-right: 80px;
    float: left;
  }
  .riivosPic {
    width: 20%;
    float: left;
  }
  .picture {
    width: 70%;
    height: 70%;
  }
  .riivosDescription, .ucsdDescription {
    float: left;
    font-size: 32px;
    width: 80%;
  }
  .neuDescription {
    float: left;
    font-size: 32px;
    width: 80%;
  }
  .riivos, .riivosIntern {
    padding-left: 10%;
    padding-right: 10%;
    font-weight: 400px;
    min-height: 400px;
    position: relative;
  }
  .middleBar {
    left: 0;
    text-align: start;
    padding: 0px 20px 5px 70px;
    font-size: 2em;
    border-bottom: 5px solid black;
    margin-bottom: 100px;
  }
  .info{
    padding: 30px 30px 20px 70px;
    text-align: center;
    display: block;
    height: auto;
  }
  .riivosContent {
    font-size: 16px;
    text-align: start;
    display: block;
  }
  .riivosTitle {
    text-align: start;
  }
}

@media (max-width: 700px) {
  .quote {
    text-align: center;
    font-size: 28px;
    display: inline-block;
    max-width: 400px;
  }
  .riivosPic {
  }
  .picture {
    max-height: 250px;
    max-width: 250px;
  }
  .neuPic {
      width: 70%;
      height: 70%;
  }
  .profile {
    display: inline-block;
  }

  .riivosDescription {
    display: inline-block;
    font-size: 32px;
    width: 80%;
    margin-top: -30px;
  }

  .ucsdDescription, .neuDescription {
    display: inline-block;
    font-size: 32px;
    width: 80%;
  }

  .riivos, .riivosIntern {
    padding-left: 10%;
    padding-right: 10%;
    font-weight: 400px;
    min-height: 400px;
    position: relative;
    text-align: center;
    margin-bottom: 20px;
  }
  .middleBar {
    left: 0;
    text-align: center;
    font-size: 2em;
    border-bottom: 5px solid black;
    margin-bottom: 0px;
  }
  .info{
    padding: 30px 0px 20px 0px;
    text-align: center;
    display: block;
    height: auto;
  }
  .riivosContent {
    font-size: 16px;
    text-align: start;
    display: block;
    margin-left: 30px;
    margin-top: 20px;
  }
  .riivosTitle {
    text-align: start;
  }
}

.info::after {
  content: " ";
  clear: both;
  display: block;
}
.quoteName {
  float:right;
}


.name {
  font-size: 2em;
  line-height: normal;
  font-family: Avenir, sans-serif;
}


.NavBar {
    color: #fff;
    position: fixed;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    z-index: 10;
    margin-top: 5px;
}

.Resume {
  display: none;
  padding: 40px 40px 40px 25px;
  opacity: 1;
}
.show {
  display:inline !important;
}

.App-header {
  background-image: url('start.jpg');
  background-position: center;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  opacity: 9;
  z-index: 1;
}

.App-headergif {
  background-image: url('background1.gif');
  background-position: center;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;
  height: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  opacity: 9;
  z-index: 2;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
